import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import IconXls from '../../assets/images/icons/xls.svg'
import { multiElipsis } from '../../assets/stylesheets/bases_ts.styles'

const ClearStyles = css`
  color: var(--pri-clr-lnk);
  &, &:hover {
    text-decoration: none;
  }
  &:hover {
    color: var(--pri-clr-lnk__hvr);
  }
`
const ClearRStyles = css`
  color: var(--pri-clr-lnk);
  &, &:hover {
    text-decoration: none;
  }
  &:hover {
    color: var(--sec-clr-lnk__hvr);
  }
`
const ButtonStyles = css`
  position: relative;
  display: inline-grid;
  padding: 0 16px;
  border-radius: var(--sec-rd);
  outline: none;
  height: 30px;
  align-items: center;
  transform-origin: 100% 0%;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  &, &:hover {
    text-decoration: none;
  }
`

const getLinkModifiedStyled = ({ mode, elipsis, activated, shadow, iconRight, disabled }:{ mode?: string, elipsis?: number, activated?: number, shadow?: number, iconRight?: string, disabled?: number }) => {
  let style = ""
  if (mode === 'clear') {
    style += ClearStyles
  }
  else if (mode === 'clearR') {
    style += ClearRStyles
  }
  else if (mode === 'blue') {
    style += `
      &, &:hover {
        color: var(--pri-clr-lnk__hvr);
        text-decoration: none;
      }
    `
  }
  else if (mode === 'trans') {
    style += ButtonStyles + `
      height: auto;
      padding: 0;
      opacity: 1;
      &, &:hover { 
        color: var(--pri-clr-lnk__hvr);
        text-decoration: none;
      }  
      &:hover {
        opacity: .9; 
      }
    `
  }
  else if (mode === 'hover') {
    style += ButtonStyles + `
      height: auto;
      width: max-content;
      padding: 3px 6px;
      position: relative;
      left: -6px;
      color: initial;
      grid-gap: 4px;
      &:hover { 
        border-radius: var(--ter-rd);
        box-shadow: 1px 1px var(--pri-clr-ln);
        background-color: var(--mt-clr-bg__fc);
        text-decoration: none;
      }
    `
  }
  else if (mode === 'whiteB') {
    style += ButtonStyles + `
      color: var(--ter-clr);
      background-color: var(--cl-clr-bg);
      border: 1px solid var(--sec-clr-ln);
      &, &:hover { 
        background-color: var(--cl-clr-bg__hvr);
      }  
    `
  }
  else if (mode === 'redB') {
    style += ButtonStyles + `
      color: var(--act-clr);
      background-color: var(--act-clr-bg);
      &:hover {
        background-color: var(--act-clr-bg__hvr);
      } 
    `
  }
  else if (mode === 'greyB') {
    style += ButtonStyles + `
      color: var(--act-clr);
      background-color: var(--mt-clr-bg__fc);
      &:hover {
        background-color: var(--cl-clr-bg__hvr);
      } 
    `
  }
  else if(mode === 'table') {
    style += `
      display: block;
      width: 100%;
      text-wrap: wrap;
      word-wrap: break-word;
      font-size: 13px;
    `
  }
  if (activated) {
    style += `
      color: var(--sta-clr);
      font-weight: 400;
    `
    if (mode === 'clear' || mode === 'clearR') { 
      style += `
        color: var(--pri-clr-lnk__act);
        font-weight: 600;
      `
    }
  }
  if (elipsis) {
    style += `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      max-width: 100%;
      width: auto;
      ${multiElipsis}
    `
  }
  if (shadow) {
    style += `
      box-shadow: var(--act-shd-bx);
    `
  }
  if (iconRight === 'xls') {
    style += `
      position: relative;
      padding-right: 21px;
      &:before { 
        content: url(${IconXls}); 
        position: absolute;
        right: 0px;
        top: -4px;
        width: 18px;
        height: 18px;
      }
    `
  }
  if(disabled) {
    style += `
      color: #A9A9A9;
      opacity: 0.7;
      pointer-events: none;
    `
  } 
  return style
}

export const LinkContainer = styled(Link)<{ mode?: string, elipsis?: number, activated?: number, shadow?: number, iconRight?: string }>`
  ${getLinkModifiedStyled}
`
export const AnchorLinkContainer = styled.a`
  ${getLinkModifiedStyled}
`
export const ElLinkContainer = styled.span`
  cursor:pointer;
  ${getLinkModifiedStyled}
`